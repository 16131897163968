import React, { useState } from "react";
import { Box } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { EditButton, DeleteButton } from "components";
import { useSelector } from "react-redux";

export const CandidatesTable = () => {
  const candidates = useSelector((s: any) => s.passageBooker.candidates);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(10);
  const [total, setTotal] = useState(candidates.length);

  return (
    <>
      <Box
        data-testid="passage-booker-list"
        className="passage-booker-list"
        mx={3}
      >
        <Table>
          <TableHead>
            <TableRow bg="fill.0">
              <TableCell width="10">Id</TableCell>
              <TableCell width="auto">Start</TableCell>
              <TableCell width="auto">End</TableCell>
              <TableCell width="auto">GS</TableCell>
              <TableCell width="auto">Max Elevation</TableCell>
              <TableCell width="auto">Satellite</TableCell>
              <TableCell width="auto">Provider</TableCell>
              <TableCell width="auto">Editable</TableCell>
              <TableCell width="auto">Priority</TableCell>
              <TableCell width="auto">Optimal</TableCell>
              <TableCell width="auto">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates?.length === 0 && <Box m={4}>Loading...</Box>}
            {Array.isArray(candidates) &&
              candidates.map((candidate: any, idx: number) => {
                return (
                  <TableRow key={candidate.passageID}>
                    <TableCell width="2%">
                      <Link
                        to={{ pathname: `/edit-passage`, state: { candidate } }}
                      ></Link>
                      {candidate.passageID}
                    </TableCell>
                    <TableCell width="1%">
                      {new Date(candidate.aos).toLocaleString()}
                    </TableCell>
                    <TableCell width="auto">
                      {new Date(candidate.los).toLocaleString()}
                    </TableCell>
                    <TableCell>{candidate.groundStationName}</TableCell>
                    <TableCell>{candidate.maxElevation}</TableCell>
                    <TableCell align="center">
                      {candidate.satelliteID}
                    </TableCell>
                    <TableCell align="center" width="4%">
                      {candidate.leafSpacePassageID?.leafSpacePassageID?.toUpperCase()}
                    </TableCell>
                    <TableCell align="center">
                      {candidate.is_editable}
                    </TableCell>
                    <TableCell align="center">{candidate.priority}</TableCell>
                    <TableCell align="center">{candidate.is_optimal}</TableCell>
                    <TableCell align="center">
                      {idx % 2 === 0 ? "UNBOOKED" : "BOOKED"}{" "}
                    </TableCell>
                    <TableCell>
                      <Link to={`#`}>
                        {idx % 2 === 0 ? (
                          <EditButton disabled={false}>BOOK </EditButton>
                        ) : (
                          <DeleteButton>CANCEL</DeleteButton>
                        )}
                      </Link>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <PaginationSimple
          total={total}
          page={page + 1}
          pageSize={pageSize}
          nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
          previousPageUrl={page - 1}
          onChange={(newPage: string | number) => {
            setPage(Number(newPage));
            setPageSize(pageSize);
          }}
          onPageSizeChange={(newPageSize: number) => {
            setPage(0);
            setPageSize(Number(newPageSize));
          }}
        />
      </Box>
    </>
  );
};
