import {
  alertDataProvider,
  GET_LIST,
  CREATE,
  dataProvider,
  UPDATE,
  DELETE,
  GET_ONE,
  DELETE_LIST
} from "app/network";
import qs from "qs";
import { store } from "app/store";
import * as actions from "../actions";
import {
  SelectedAlert,
  GetAlertsResponse,
  AlertResponse,
  AlertDTO,
  GetAlertsDataSourceListResponse,
  AlertsDataSourceListResponse,
  Options,
  AlertConfig,
  AlertDefinitionDTO
} from "../models";
import { convertToLocalAlertModel } from "../models/dto";
import { HTTPResponse } from "app/network/dataProvider/model";
import { SET_ALERT_DEFINITIONS } from "../models/constants";

export const getAlertcount = (satelliteId: number) => {
  store.dispatch(actions.fetchAlertCountStart());

  return alertDataProvider(GET_LIST, "alert/count", {
    query: {
      "satellite-id": satelliteId,
      dismissed: "false"
    }
  })
    .then((response: any) => {
      store.dispatch(actions.fetchAlertCountSuccess(response.data));
    })
    .catch((error: any) => {
      store.dispatch(actions.fetchAlertCountError());
    });
};

export const getAlerts = (
  satelliteId: number,
  pageSize?: number,
  paginationUrl = "",
  dismissed = "false",
  datasources: number[] | null = null
): Promise<GetAlertsResponse | null> => {
  store.dispatch(actions.fetchAlertsStart());

  let requestParams: any = {
    pagination: { pageSize },
    query: {
      "satellite-id": satelliteId,
      dismissed
    }
  };

  if (datasources) {
    if (datasources.length !== 0) {
      requestParams.query.datasources = datasources.join(",");
    } else {
      store.dispatch(actions.fetchAlertsSuccess([]));
      return new Promise(() => []);
    }
  }

  if (paginationUrl) {
    const url = new URL(paginationUrl);
    requestParams = {
      filter: {
        ...qs.parse(url.search, { ignoreQueryPrefix: true })
      }
    };
  }

  return alertDataProvider(GET_LIST, "alert", requestParams)
    .then((response: any) => {
      //Convert Alert Response Data format to Alert Local Data format
      const data: AlertDTO[] = [];
      if (Array.isArray(response.data)) {
        response.data.forEach((alert: AlertResponse) => {
          data.push(convertToLocalAlertModel(alert));
        });
        response.data = data;
        //
        store.dispatch(actions.fetchAlertsSuccess(response.data));
        return response;
      }
      return response;
    })
    .catch((error: Error) => {
      store.dispatch(actions.fetchAlertsError(error.message));
      throw error;
    });
};

export const getAlertsDataSourceList = (
  satelliteId: number
): Promise<GetAlertsDataSourceListResponse | null> => {
  return alertDataProvider(GET_LIST, `alert/${satelliteId}/datasourceAlerts`)
    .then((response: any) => {
      if (response && response.data) {
        const data: AlertsDataSourceListResponse[] = [];
        response.data.forEach((d: any, index: number) => {
          const dataObj: AlertsDataSourceListResponse = {
            alert: convertToLocalAlertModel(d.alert),
            datasource: d.datasource
          };
          data.push(dataObj);
        });
        response.data = data;
        return response;
      }
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const dismissAlerts = (
  satelliteId: number,
  alerts: SelectedAlert[],
  pageSize: number,
  datasources: number[] | null = null
) => {
  store.dispatch(actions.dismissAlertsStart());
  alertDataProvider(CREATE, `alert/dismissAlertRequests`, {
    data: { satelliteId, alerts }
  })
    .then(() => {
      store.dispatch(actions.dismissAlertsSuccess());
      getAlerts(satelliteId, pageSize, "", "false", datasources);
    })
    .catch((error: any) => {
      store.dispatch(
        actions.dismissAlertsError(`Error dismissing alerts: ${error.message}`)
      );
    });
};

export const dismissAllAlerts = (
  satelliteId: number,
  datasourceId: number | null
): Promise<any> => {
  const data: any = {
    satelliteId
  };
  if (datasourceId) data.dataSourceIds = [datasourceId];

  store.dispatch(actions.dismissAlertsStart());
  return alertDataProvider(CREATE, `alert/dismissAll`, {
    data
  })
    .then((response: any) => {
      store.dispatch(actions.dismissAlertsSuccess());
      store.dispatch(actions.fetchAlertsSuccess([]));
      store.dispatch(
        actions.fetchAlertCountSuccess({
          numCritical: 0,
          numWarnings: 0,
          numOthers: 0
        })
      );
      return response;
    })
    .catch((error: Error) => {
      // eslint-disable-next-line no-console
      store.dispatch(
        actions.dismissAlertsError(`Error dismissing alerts: ${error.message}`)
      );
    });
};

export const setAlerts = (alerts: AlertDTO[]): Promise<any> => {
  return store.dispatch(actions.setAlerts(alerts));
};

export const deleteAllAlertsDefinitions = (satDefId: number) => {
  if (!satDefId) return;

  return dataProvider(
    DELETE_LIST,
    `datasource/alert-definitions/all?satDefId=${satDefId}`,
    {}
  );
};

export const getAlertsDefinitionsList = (
  satDefId: number,
  pagination: Options
) => {
  if (!satDefId) return;
  const limit = pagination.pageSize || 10;
  const offset = pagination.page * pagination.pageSize;
  return dataProvider(
    GET_LIST,
    `datasource/alert-definitions?limit=${limit}&offset=${offset}&satDefId=${satDefId}`,
    {}
  );
};

export const getAlertDefinitionById = (dataSourceId: any) => {
  return dataProvider(
    GET_ONE,
    `datasource/${dataSourceId}/alert-definition`,
    {}
  );
};

export const deleteAlertsDefinition = (dataSourceId = 1) => {
  return dataProvider(
    DELETE,
    `datasource/${dataSourceId}/alert-definition`,
    {}
  );
};

type DataSource = any;
export const createAlertDefinition = (
  alertDef: AlertDefinitionDTO,
  imported = false
) => {
  let data: any = { ...alertDef.alert, active: true };
  if (!imported) {
    data = createAlertDefPayload(alertDef);
  }
  return dataProvider(
    CREATE,
    `datasource/${alertDef.dataSourceId}/alert-definition`,
    { data }
  );
};

export const updateAlertDefinition = (
  dataSourceId: string | number,
  alertDef: AlertDefinitionDTO,
  imported = false
) => {
  let data: any = { ...alertDef, active: true };
  if (!imported) {
    data = createAlertDefPayload(alertDef);
  }
  return dataProvider(UPDATE, `datasource/${dataSourceId}/alert-definition`, {
    data
  });
};

export const getDataSourceLabels = (satDef: any) => {
  const datasources: any = [];
  satDef.systems?.map((sys: any) => {
    if (sys.systems.length) {
      sys.systems?.map((subSys: any) => {
        datasources.push(subSys.dataSources);
      });
    }
    datasources.push(sys.dataSources);
  });
  return datasources.flat();
};

const policies = ["notifyOnTeams", "notifyByEmail", "playSoundOnFE"];

const processNotificationPolicy = (input: any) => {
  if (typeof input === "object" && input !== null) {
    return policies.filter((key) => input[key]).join(",");
  }
  if (typeof input === "string") {
    return input.split(",").reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: true
      }),
      { notifyOnTeams: false, notifyByEmail: false, playSoundOnFE: false }
    );
  }
  return null;
};

const getAlertConfigs = (alerts: AlertConfig[]) => {
  return alerts.map((a: AlertConfig) => {
    const indexes = extractIndexesFromAlertCondition(a);
    return {
      name: a.name,
      condition: a.condition,
      severity: a.severity,
      instantReference: a.instantReference,
      notificationPolicy: processNotificationPolicy(a.notificationPolicy),
      indexes: indexes
    };
  });
};

const createAlertDefPayload = (alertDef: any) => {
  const config = alertDef.alertConfigs || alertDef.alert.alertConfigs;
  return {
    active: true,
    alertType: alertDef.alertType,
    datasourceId: alertDef.dataSource,
    alertConfigs: getAlertConfigs(config),
    metadata: {}
  };
};

export const formatAlertDefinition = (alertDefinition: AlertDefinitionDTO) => {
  return {
    alertType: alertDefinition.alert.alertType,
    dataSourceId: alertDefinition.dataSourceId,
    alertConfigs: alertDefinition.alert?.alertConfigs?.map((c: any) => {
      return {
        ...c,
        applyToAllIndexes: formatIndexOptions(c),
        notificationPolicy: processNotificationPolicy(c.notificationPolicy)
      };
    })
  };
};

export function extractIndexesFromAlertCondition(a: AlertConfig) {
  const alertCondition = a.condition;
  const applyToAllIndexes = a.applyToAllIndexes;
  return mapIndexesFromAlertCondition(alertCondition, applyToAllIndexes);
}

function mapIndexesFromAlertCondition(
  condition: string,
  applyToAllIndexes?: boolean
) {
  const mappedIndexes: any = {};
  if (applyToAllIndexes) {
    mappedIndexes["allIndexes"] = applyToAllIndexes;
    return mappedIndexes;
  }
  const regex = /(\w+)\[(\d+)\]|\w+/g;
  let match;
  let currentDS;
  while ((match = regex.exec(condition)) !== null) {
    const [, key, index] = match;
    if (index) {
      mappedIndexes["allIndexes"] = applyToAllIndexes;
      if (key === currentDS) {
        mappedIndexes[key].push(parseInt(index));
      } else {
        mappedIndexes[key] = [parseInt(index)];
        currentDS = key;
      }
    }
  }

  return mappedIndexes;
}

function formatIndexOptions(c: any) {
  if (c.indexes[0]) {
    return c.indexes[0].allIndexes;
  }
  return false;
}

export const getAlertDefinitions = (
  satDefId: number,
  { page = 0, pageSize = 10 }: Options
): any => {
  return async (dispatch: any) => {
    try {
      const { data: response, ...rest } = (await getAlertsDefinitionsList(
        satDefId,
        {
          page,
          pageSize
        }
      )) as HTTPResponse;
      dispatch({
        type: SET_ALERT_DEFINITIONS,
        payload: response
      });
      const data = {
        data: response,
        ...rest
      };
      return Promise.resolve(data);
    } catch (e) {
      console.log("TURBO ~ return ~ e", e);
      return Promise.reject(e);
    }
  };
};
