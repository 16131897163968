import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex } from "primitives";
import { Form } from "app/shared";
import {
  bookerConfigUISchemEdit,
  bookerConfigSchemaEdit
} from "app/passageBooker/models/schema";
import { fetchGroundStationsAction as gs } from "app/groundStation/actions";
import { createEditFormData } from "app/passageBooker/utils";

export const BookerForm = (props: any) => {
  const type = props.type;
  const [selectedConfigId, setSelectedConfigId] = useState("");
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});

  const dispatch = useDispatch();
  const satellites = useSelector((s: any) => s.constellations.dashboard);
  useEffect(() => {
    dispatch(gs());
  }, []);

  const groundStations = useSelector(
    (s: any) => s.groundStations?.groundStations
  );

  const passageBookerConfigs = useSelector(
    (s: any) => s.passageBooker.bookerConfigs
  );
  const validator = (formData: any, errors: any) => {
    return errors;
  };

  const fmtDateRange = (formData: any) => {
    if (formData.daterange?.from && formData.daterange?.to) {
      formData.daterange.from = new Date(
        formData?.daterange?.from
      ).toISOString();
      formData.daterange.to = new Date(formData?.daterange?.to).toISOString();
    }
    return formData;
  };

  const onChange = useCallback(
    (form: any) => {
      const configId = form.formData.configuration;
      setSelectedConfigId(configId);
      if (configId) {
        const selectedConfig = passageBookerConfigs.find(
          (pc: any) => pc.id === configId
        );
        if (selectedConfig) {
          const formattedData = createEditFormData(
            selectedConfig,
            satellites,
            groundStations
          );
          setInitialFormData(formattedData);
          const mergedFormData = {
            ...formattedData,
            ...form.formData,
            name: form.formData.name || formattedData.name,
            description: form.formData.description || formattedData.description,
            daterange: {
              ...formattedData.daterange,
              ...form.formData.daterange
            },
            groundStationsBySatellite: form.formData.groundStationsBySatellite
              ?.length
              ? form.formData.groundStationsBySatellite
              : formattedData.groundStationsBySatellite
          };
          setFormData(fmtDateRange(mergedFormData));
        }
      } else {
        setFormData(form.formData);
      }
    },
    [passageBookerConfigs, satellites, groundStations]
  );

  return (
    <Flex flexDirection="column" bg="fill.0" p={5}>
      <Form
        ref={props.formRef}
        id="passage-booker"
        validate={(formData: any, errors: any) => validator(formData, errors)}
        formData={fmtDateRange(formData)}
        schema={bookerConfigSchemaEdit(
          satellites,
          groundStations,
          type,
          passageBookerConfigs
        )}
        uiSchema={bookerConfigUISchemEdit}
        disabled={false}
        onSubmit={(
          form: { formData: any },
          e: React.FormEvent<HTMLInputElement>
        ) => {
          return props.submit(e, form.formData);
        }}
        onChange={(form: any) => onChange(form)}
      >
        <></>
      </Form>
    </Flex>
  );
};
