import { dataProvider, DELETE, CREATE, UPDATE, GET_LIST } from "app/network";
import { passageBookerDataProvider } from "app/network/dataProvider";
import { mockPassages } from "../utils/mocks";

// TLE
export const createTLE = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const updateTLE = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const deleteTLE = (data: any) => {
  return dataProvider(DELETE, `passages`, data).then(() => {
    return data;
  });
};

export const getTLE = (data: any) => {
  return dataProvider(GET_LIST, `passages`, data).then(() => {
    return data;
  });
};

// BOOKING
export const getPassagesListForBooking = (data: any) => {
  const filteredPassages = filterPassages(mockPassages, data);
  return filteredPassages;
  return dataProvider(GET_LIST, `passages`, data).then(() => {
    return data;
  });
};

export const bookingSinglePassage = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const cancelSinglePassageBooker = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const cancelMultiplePassageBooker = (data: any) => {
  return dataProvider(CREATE, `passages`, data).then(() => {
    return data;
  });
};

export const bookingMultiplePassages = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const updatePassage = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const updateConfig = (data: any) => {
  return dataProvider(UPDATE, `passages`, data).then(() => {
    return data;
  });
};

export const getPassageCandidatesForConfig = (configId: any) => {
  return passageBookerDataProvider(
    GET_LIST,
    `candidates?bookingConfigurationID=${configId}`
  );
};

export const getBookerConfigs = (pagination: any) => {
  const offset =
    (Math.max(pagination?.page || 1, 1) - 1) * (pagination?.pageSize || 20);
  const limit = pagination?.pageSize || 20;
  return passageBookerDataProvider(
    GET_LIST,
    `configurations?limit=${limit}&offset=${offset}`
  );
};

export const createBookerConfigs = (data: any) => {
  return passageBookerDataProvider(CREATE, `configurations`, { data }).then(
    ({ data }) => {
      return data;
    }
  );
};

export const updateBookerConfigs = (id: any, data: any) => {
  return passageBookerDataProvider(UPDATE, `configurations`, { id, data }).then(
    ({ data }) => data
  );
};

export const deleteBookerConfig = async (id: any) => {
  return await passageBookerDataProvider(DELETE, `configurations`, { id });
};

function filterPassages(passages: any[], filters: any) {
  const { groundStationsBySatellite: filterCriteria, daterange } = filters;
  const filteredPassages = passages.filter((passage) => {
    return filterCriteria.some(
      (criteria: { groundStations: string | any[]; satellite: any }) =>
        criteria.groundStations.includes(passage.gs) &&
        criteria.satellite === passage.sat &&
        new Date(daterange.from) <= new Date(passage.end_time) &&
        new Date(daterange.to) >= new Date(passage.start_time)
    );
  });
  const sortedPassages = filteredPassages.sort(
    (a, b) =>
      new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
  );
  return sortedPassages;
}
