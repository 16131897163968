import React, { useState, useEffect } from "react";
import { Box } from "primitives";
import { Table, TableHead, TableBody, TableRow, TableCell } from "components";
import { Link } from "react-router-dom";
import { PaginationSimple } from "app/shared";
import { EditButton, DeleteButton } from "components";
import { BookerConfiguration } from "../../models";
import { getPassageBookerConfigs } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { ConfigEditContainer } from "./ConfigEditContainer";
import { ConfigDeleteContainer } from "./ConfigDeleteContainer";
import { fetchGroundStationsAction as gs } from "app/groundStation/actions";

export const ConfigsTable = () => {
  const { bookerConfigs, pagination } = useSelector(
    (s: any) => s.passageBooker
  );
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(pagination?.total);

  useEffect(() => {
    dispatch(gs());
    dispatch(getPassageBookerConfigs({ page, pageSize }));
  }, [page, pageSize]);

  // @ts-ignore
  let styles: Properties<string | number> = {
    whiteSpace: "normal",
    wordWrap: "break-word"
  };
  return (
    <>
      <Box
        data-testid="passage-booker-config-list"
        className="passage-booker-list"
        mx={3}
      >
        <Table>
          <TableHead>
            <TableRow bg="fill.0">
              <TableCell width="10">ID</TableCell>
              <TableCell width="auto">Name</TableCell>
              <TableCell width="auto">Description</TableCell>
              <TableCell width="auto">Start</TableCell>
              <TableCell width="auto">End</TableCell>
              <TableCell width="auto" style={styles}>
                Satellites
              </TableCell>
              <TableCell width="auto" style={styles}>
                GroundStations
              </TableCell>
              <TableCell width="auto"></TableCell>
              <TableCell width="auto"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(bookerConfigs) &&
              bookerConfigs.map((config: BookerConfiguration, idx: number) => {
                return (
                  <TableRow key={config.id}>
                    <TableCell width="2%">
                      <Link
                        to={{
                          pathname: `/edit-passage`,
                          state: { passage: config }
                        }}
                      ></Link>
                      {config.id}
                    </TableCell>
                    <TableCell align="center">{config.name}</TableCell>
                    <TableCell align="center">{config.description}</TableCell>
                    <TableCell width="1%">
                      {new Date(config.startTime).toLocaleString()}
                    </TableCell>
                    <TableCell width="auto">
                      {new Date(config.endTime).toLocaleString()}
                    </TableCell>
                    <TableCell style={styles as any}>
                      {Object.keys(config?.groundStationsBySatellite)?.join(
                        ",  "
                      )}
                    </TableCell>
                    <TableCell style={styles as any}>
                      {Object.values(config?.groundStationsBySatellite)?.join(
                        ",  "
                      )}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <ConfigEditContainer config={config} />
                    </TableCell>
                    <TableCell align="center">
                      <ConfigDeleteContainer
                        configId={config.id}
                        onChange={() => dispatch(getPassageBookerConfigs())}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <PaginationSimple
          total={total}
          page={page + 1}
          pageSize={pageSize}
          nextPageUrl={(page + 1) * pageSize < total ? page + 1 : 0}
          previousPageUrl={page - 1}
          onChange={(newPage: string | number) => {
            setPage(Number(newPage));
            setPageSize(pageSize);
          }}
          onPageSizeChange={(newPageSize: number) => {
            setPage(0);
            setPageSize(Number(newPageSize));
          }}
        />
      </Box>
    </>
  );
};
